<template>
    <v-row v-if="loading" class="a-list-row ma-0 mb-2 white pt-1 pb-1">
        <v-col cols="1" class="pl-3 pr-0 pt-3 pb-3 ma-auto">
            <v-skeleton-loader type="heading" />
        </v-col>
        <v-col v-if="hideAuthor" cols="2" class="pl-2 pr-0 pt-4 ma-auto">
            <v-skeleton-loader
                type="text"
                :width="randomBetween(45, 75) + '%'"
            />
        </v-col>
        <v-col cols="2" class="pl-2 pr-0 pt-4 ma-auto">
            <v-skeleton-loader
                type="text"
                :width="randomBetween(45, 75) + '%'"
            />
        </v-col>
        <v-col cols="2" class="pl-2 pr-0 pt-4 ma-auto">
            <v-skeleton-loader
                type="text"
                :width="randomBetween(35, 85) + '%'"
            />
        </v-col>
        <v-col cols="1" class="pl-2 pr-0 pt-4 ma-auto">
            <v-skeleton-loader
                type="text"
                :width="randomBetween(20, 35) + '%'"
            />
        </v-col>
        <v-col cols="2" class="pl-2 pr-0 pt-4 ma-auto">
            <v-skeleton-loader
                type="text"
                :width="randomBetween(30, 45) + '%'"
            />
        </v-col>
        <v-col cols="1" class="pl-2 pr-0 pt-4 ma-auto">
            <v-skeleton-loader
                type="text"
                :width="randomBetween(30, 45) + '%'"
            />
        </v-col>
        <v-col v-if="!hideAuthor" cols="2" class="pl-2 pr-0 pt-4 ma-auto">
            <v-skeleton-loader
                type="text"
                :width="randomBetween(30, 45) + '%'"
            />
        </v-col>
        <v-col cols="1" class="pl-2 pr-0 pt-4 py-0 ma-auto" />
    </v-row>
    <v-hover v-else v-slot="{ hover }">
        <v-row
            class="a-list-row ma-0 mb-2 align-center"
            :class="{
                white: !hover && !isActive,
                'concrete-solid': hover || isActive,
                'elevation-2': hasAction && (hover || isActive),
                'px-2': $vuetify.breakpoint.mdAndDown,
                'with-action': hasAction
            }"
            @click="onAction"
        >
            <v-col cols="1" class="position--relative">
                <status-chip
                    :status="isRejected ? 'Rejected' : assignment.status_string"
                    :tooltip="blockedReason"
                />
                <v-tooltip v-if="hasNewComments" bottom z-index="10">
                    <template #activator="{ on, attrs }">
                        <div
                            v-bind="attrs"
                            class="new-comments--circle new-comments--circle-list"
                            v-on="on"
                        ></div>
                    </template>
                    <span>New comments in the last 48 hours</span>
                </v-tooltip>
            </v-col>
            <v-col
                v-if="hideAuthor"
                cols="2"
                class="pl-2 pr-0 ma-auto"
                :class="{
                    'cell-disabled': !hasHeadLine
                }"
            >
                <text-truncated :text="headline" />
            </v-col>
            <v-col
                cols="2"
                class="pl-2 pr-0 ma-auto"
                :class="{
                    'cell-disabled': !hasCategory
                }"
            >
                <text-truncated :text="category" />
            </v-col>
            <v-col
                cols="2"
                class="pl-2 pr-0 ma-auto"
                :class="{
                    'cell-disabled': !hasCompanyName
                }"
            >
                <text-truncated :text="companyName" />
            </v-col>
            <v-col cols="1" class="pl-2 pr-0 ma-auto">
                <author-request-type-chip
                    :request="request"
                    :assignment="assignment"
                />
            </v-col>
            <v-col cols="2" class="pl-2 pr-0 py-0 ma-auto">
                <v-row no-gutter class="ma-0 pa-0">
                    <v-col class="d-flex align-center pa-0 ma-0">
                        <a-priority-select-input
                            v-model="priorityLevel"
                            item-value="priority_level"
                            :items="options"
                            :disabled="isSaving || !canSetPriority"
                            @change="prioritySelectionChanged"
                            @click.stop
                        />
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="1" class="pl-2 pr-0 ma-auto">
                {{ daysInQueue }}
            </v-col>
            <v-col v-if="!hideAuthor" cols="2" class="pl-2 pr-0">
                {{ authorName }}
            </v-col>
            <v-col cols="1" class="pl-2 pr-0 py-0 ma-auto" align="right">
                <assignment-row-actions
                    :assignment="assignment"
                    @open="markRowActive"
                    @redirect="redirect"
                    @show-comments="showComments"
                />
            </v-col>
        </v-row>
    </v-hover>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component, { mixins } from 'vue-class-component';

import { PrEvents } from '@/mixins';
import { isTextSelectionEvent, randomBetween } from '@/utils/helpers';
import { InjectReactive } from '@/utils/decorators';

import { AuthorRequestMedia } from '@/components/Authoring/AuthorRequestMedia';
import AssignmentRowActions from './AssignmentRowActions.vue';
import AuthorRequestTypeChip from '@/components/Authoring/AuthorRequestsList/AuthorRequestTypeChip.vue';
import { StatusChip } from '@/components/StatusChip';
import { TextTruncated } from '@/components/TextTruncated';
import APrioritySelectInput from '@/components/AForm/Inputs/APrioritySelectInput';

import type { AssignmentAction } from '@/components/Authoring/AssignmentActions/AssignmentActionsFactory';
import type { Assignment } from '@/types/Assignment';
import { PrEventMessageType } from '@/types/PrEvent';

const AssignmentsListRowProps = Vue.extend({
    name: 'AssignmentsListRow',
    props: {
        assignment: {
            type: Object as PropType<Assignment>,
            default(): Assignment | null {
                return null;
            }
        },
        action: {
            type: Object as PropType<AssignmentAction>,
            default(): AssignmentAction | null {
                return null;
            }
        },
        loading: {
            type: Boolean,
            default() {
                return false;
            }
        },
        canSetPriority: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component({
    components: {
        APrioritySelectInput,
        AssignmentRowActions,
        AuthorRequestTypeChip,
        AuthorRequestMedia,
        TextTruncated,
        StatusChip
    }
})
export default class AssignmentsListRow extends mixins(
    AssignmentsListRowProps,
    PrEvents
) {
    isActive = false;
    isSaving = false;

    @InjectReactive({
        from: 'hideAuthor',
        default() {
            return false;
        }
    })
    hideAuthor!: boolean;

    get request() {
        return this.assignment.author_request;
    }

    get priorityLevel() {
        return this.assignment.priority_level || null;
    }

    set priorityLevel(value) {
        if (value) {
            this.assignment.priority_level = value;
        }
    }

    get authorName() {
        if (!this.assignment?.author) {
            return '-';
        }
        return [
            this.assignment?.author.firstname,
            this.assignment?.author.lastname
        ]
            .filter(Boolean)
            .join(' ');
    }

    get headline() {
        return (
            this.assignment?.announcement?.press_release?.headline ||
            'No Headline Written'
        );
    }

    get hasHeadLine() {
        return this.assignment?.announcement?.press_release?.headline;
    }

    get companyName() {
        return this.request?.company?.name || 'No Company Selected';
    }

    get hasCompanyName() {
        return this.request?.company?.name;
    }

    get category() {
        return this.request?.category?.name || 'No Category Selected';
    }

    get hasCategory() {
        return this.request?.category?.name;
    }

    get hasAction() {
        return Boolean(this.action);
    }

    get daysInQueue() {
        if (!this.assignment?.created) {
            return 1;
        }

        return (
            this.$dayjs().diff(
                this.$dayjs.unix(this.assignment?.created),
                'days'
            ) || 1
        );
    }

    get events() {
        return this.assignment?.announcement?.press_release?.pr_events;
    }

    get hasNewComments() {
        if (!this.events) {
            return false;
        }

        return this.getNewCommentsForLast48Hours(this.events);
    }

    get isRejected() {
        return this.assignment?.announcement?.press_release?.status === 3;
    }

    get blockedReason() {
        if (this.assignment?.status !== 4 || !this.events) {
            return '';
        }

        const lastEvent = this.events
            .reverse()
            .find(event => event.message_type === PrEventMessageType.Admin);

        return lastEvent ? lastEvent.message : '';
    }

    get options() {
        return [
            { name: 'High', color: 'red', priority_level: 1 },
            { name: 'Medium', color: 'orange', priority_level: 2 },
            { name: 'Low', color: 'yellow', priority_level: 3 },
            { name: 'None', color: 'grey', priority_level: 4 }
        ];
    }

    randomBetween = randomBetween;

    markRowActive(isActive: boolean) {
        this.isActive = isActive;

        this.$emit('activate', this.assignment);
    }

    showComments() {
        this.$emit('show-comments', this.assignment.id);
    }

    onAction(e: Event) {
        if (this.hasAction && !isTextSelectionEvent(e)) {
            const result = this.action.callback(this, this.assignment);

            if (this.action.route && result) {
                this.$router.push(result);
            }
        }
    }

    redirect(location: string) {
        window.location.href = location;
    }

    prioritySelectionChanged() {
        this.isSaving = true;

        this.$http
            .post(`/author_requests_pool/prioritize/${this.assignment.id}`, {
                priority_level: this.priorityLevel,
                is_priority: this.request?.is_priority
            })
            .then(({ data }) => {
                if (data.data.error && data.data.message) {
                    this.$store.dispatch(
                        'notification/error',
                        data.data.message
                    );
                } else {
                    this.$store.dispatch(
                        'notification/success',
                        'Priority updated. List will be refreshed.'
                    );
                }
            })
            .catch(error => {
                if (!error.isIntercepted) {
                    this.$store.dispatch('notification/error', error);
                }
            })
            .finally(() => {
                this.isSaving = false;
                this.$emit('update', true);
            });
    }
}
</script>

<style lang="scss">
@import '@/components/AList/AList.scss';
</style>
