<template>
    <v-card
        v-if="loading"
        class="amp-card amp-card--top-border unapproved pt-3 pl-2"
        style="padding-bottom: 1px"
    >
        <v-skeleton-loader
            type="heading"
            style="margin-top: -2px"
            :width="randomBetween(25, 55) + '%'"
        />
        <v-list-item three-line class="pa-0 pl-2">
            <v-avatar rounded size="100" color="main-background">
                <v-icon color="grey lighten-2" large>image</v-icon>
            </v-avatar>
            <v-list-item-content class="mt-2">
                <v-skeleton-loader
                    class="pl-4"
                    type="heading"
                    :width="randomBetween(5, 15) + '%'"
                />
                <v-skeleton-loader
                    type="list-item-two-line"
                    :width="randomBetween(35, 55) + '%'"
                />
            </v-list-item-content>
        </v-list-item>
    </v-card>
    <v-card
        v-else
        class="amp-card amp-card--top-border"
        :class="rootClass"
        @[actionBinder]="onAction"
    >
        <div class="status-bar mt-2 ml-2">
            <status-chip :status="status" />

            <status-chip
                v-if="isOverdue"
                :tooltip="overdueTooltip"
                color="white"
                icon-only
            >
                <template #icon>
                    <v-icon color="error" small>far fa-clock</v-icon>
                </template>
            </status-chip>

            <status-chip
                v-if="isFirstRecurring"
                status="First Recurring Request"
                class="mr-1 font-weight-black text-button"
                label="1"
            />

            <status-chip
                v-if="isLimitedDistribution"
                status="Selective Distribution"
            />

            <status-chip v-if="isPremium" status="Premium" />

            <status-chip v-if="isFinancial" status="Financial" />

            <status-chip v-if="isMsn" status="MSN" />
        </div>

        <v-list-item three-line>
            <author-request-media
                :assignment="assignment"
                :request="assignment.author_request"
                :size="100"
                secondary
                class="ma-4 ml-0"
            />

            <v-list-item-content class="mt-2">
                <v-list-item-subtitle
                    v-if="category"
                    data-test="category_name"
                    class="align-self-start"
                >
                    {{ category }}
                </v-list-item-subtitle>

                <v-list-item-title
                    v-if="companyName"
                    class="align-self-start headline text-body-1 font-weight-bold"
                    data-test="company_name"
                >
                    <text-truncated :text="companyName" />
                </v-list-item-title>

                <v-list-item-subtitle
                    class="align-self-end text-right amp-card--date mt-2"
                    data-test="status_and_date"
                >
                    {{ statusAndDate }}
                </v-list-item-subtitle>

                <v-list-item-subtitle
                    v-if="isRecurring"
                    class="align-self-end text-right amp-card--date mt-2"
                >
                    {{ recurringTooltip }}
                </v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
    </v-card>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { mapGetters } from 'vuex';

import {
    formatDate,
    formatDateTime,
    isTextSelectionEvent,
    randomBetween,
    snakeCase
} from '@/utils/helpers';

import { AuthorRequestMedia } from '@/components/Authoring/AuthorRequestMedia';
import { StatusChip } from '@/components/StatusChip';
import { TextTruncated } from '@/components/TextTruncated';

import type { AssignmentAction } from '@/components/Authoring/AssignmentActions/AssignmentActionsFactory';

import type { Assignment } from '@/types/Assignment';

const AssignmentCardProps = Vue.extend({
    name: 'AssignmentCard',
    props: {
        assignment: {
            type: Object as PropType<Assignment>,
            default(): Assignment | null {
                return null;
            }
        },
        action: {
            type: Object as PropType<AssignmentAction>,
            default(): AssignmentAction | null {
                return null;
            }
        },
        loading: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component({
    components: {
        StatusChip,
        TextTruncated,
        AuthorRequestMedia
    },
    computed: {
        ...mapGetters('user', ['isAuthor', 'isEditor'])
    }
})
export default class AssignmentCard extends AssignmentCardProps {
    isAuthor!: boolean;
    isEditor!: boolean;

    get status() {
        return this.assignment.status_string || '';
    }

    get rootClass() {
        return [snakeCase(this.status), 'd-flex', 'flex-column', 'flex-grow-1']
            .filter(Boolean)
            .join(' ');
    }

    get isOverdue() {
        return (this.isAuthor || this.isEditor) && this.assignment.overdue;
    }

    get overdueTooltip() {
        return this.assignment.created
            ? `The latest assignment for this amp is overdue. Created on ${formatDate(
                  this.assignment.created
              )}`
            : '';
    }

    get isFirstRecurring() {
        return (
            this.assignment.is_first_recurring &&
            this.assignment.author_request.frequency !== null
        );
    }

    get isLimitedDistribution() {
        return this.assignment.limited_distribution_only ?? false;
    }

    get isPremium() {
        return this.assignment.announcement?.is_premium ?? false;
    }

    get isFinancial() {
        return this.assignment.announcement?.is_financial ?? false;
    }

    get isMsn() {
        return this.assignment.announcement?.is_msn ?? false;
    }

    get category() {
        return this.assignment.author_request?.category?.name || '';
    }

    get companyName() {
        return this.assignment.author_request?.company?.name;
    }

    get isRecurring() {
        return (
            this.assignment.author_request?.requested_time &&
            !this.assignment.author_request?.rejected_time &&
            this.assignment.author_request.frequency !== null
        );
    }

    get recurringTooltip() {
        if (this.assignment.author_request.paused_at) {
            return 'Recurring Request';
        }

        return `Next Amp on ${this.nextAmpAt}`;
    }

    get nextAmpAt() {
        if (
            this.assignment &&
            this.assignment.author_request?.requested_time &&
            this.assignment.author_request?.frequency
        ) {
            if (
                (this.assignment.created || 0) >
                this.assignment.author_request?.requested_time
            ) {
                return this.nextAmpAtDate(
                    this.assignment.created || 0,
                    this.assignment.author_request.frequency
                );
            }

            return this.nextAmpAtDate(
                this.assignment.author_request.requested_time,
                this.assignment.author_request.frequency
            );
        }

        return '';
    }

    get statusAndDate() {
        if (this.assignment.author_request?.rejected_time) {
            return `Rejected ${formatDate(
                this.assignment.author_request.rejected_time
            )}`;
        }

        if (this.isAuthor && this.assignment?.announcement?.press_release) {
            return `Last Edited ${formatDate(
                this.assignment.announcement.press_release.modified
            )}`;
        }

        if (this.assignment.author_request?.requested_time) {
            return `Submitted ${formatDate(
                this.assignment.author_request.requested_time
            )}`;
        }

        return this.assignment.author_request?.created
            ? `Requested ${formatDate(this.assignment.author_request.created)}`
            : '';
    }

    get hasAction() {
        return Boolean(this.action);
    }

    get actionBinder() {
        return this.hasAction ? 'click' : 'nonce';
    }

    get activeAction() {
        return this.hasAction ? this.onAction : null;
    }

    nextAmpAtDate(date: number, frequency: number) {
        return formatDateTime(
            this.$date.unix(date).add(frequency, 'seconds').unix()
        );
    }

    onAction(e: Event) {
        if (this.hasAction && !isTextSelectionEvent(e)) {
            const result = this.action.callback(this, this.assignment);

            if (this.action.route && result) {
                this.$router.push(result);
            }
        }
    }

    formatDate = formatDate;
    randomBetween = randomBetween;
}
</script>

<style lang="scss" scoped>
@import '@/components/ACard/ACard.scss';
</style>
