<template>
    <v-menu
        transition="slide-y-transition"
        :close-on-click="true"
        :close-on-content-click="true"
        :offset-y="true"
        left
        @input="open"
    >
        <template #activator="{ on, attrs }">
            <v-btn fab plain v-bind="attrs" v-on="on">
                <v-icon>ellipsis-v</v-icon>
            </v-btn>
        </template>
        <v-list>
            <v-list-item
                v-for="(action, key) in actions"
                :key="'authoring--action-pool-' + key"
                :to="getHref(action)"
                @click="onAction(action)"
            >
                <v-list-item-title
                    :class="{
                        'pl-0 ml-n1': action.iconText
                    }"
                >
                    <v-icon v-if="action.icon" small left>
                        {{ action.icon }}
                    </v-icon>
                    <v-chip
                        v-else-if="action.iconText"
                        color="secondary"
                        text-color="white"
                        class="pa-1 mr-1 justify-center chip-as-icon"
                        small
                        link
                    >
                        {{ action.iconText }}
                    </v-chip>
                    {{ action.name }}
                </v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { mapGetters } from 'vuex';

import AssignmentActionsFactory from '@/components/Authoring/AssignmentActions/AssignmentActionsFactory';

import type { Assignment } from '@/types/Assignment';
import type { AssignmentAction } from '@/components/Authoring/AssignmentActions/AssignmentActionsFactory';

const AssignmentRowActionsProps = Vue.extend({
    name: 'AssignmentRowActions',
    props: {
        assignment: {
            type: Object as PropType<Assignment>,
            default(): Assignment | null {
                return null;
            }
        }
    }
});

@Component({
    computed: {
        ...mapGetters('user', ['isAuthor', 'isEditor'])
    }
})
export default class AssignmentRowActions extends AssignmentRowActionsProps {
    isAuthor!: boolean;
    isEditor!: boolean;

    actionsCache: AssignmentAction[] | null = null;

    get actions() {
        if (!this.assignment.id) {
            return [];
        }

        if (!this.actionsCache) {
            const AuthoringActions = new AssignmentActionsFactory(
                this.assignment,
                this.isEditor,
                this.isAuthor
            );

            this.actionsCache = AuthoringActions.getActions();
        }

        return this.actionsCache;
    }

    getHref(action: AssignmentAction) {
        return action.route ? this.execute(action) : void 0;
    }

    open(isOpen: boolean) {
        this.$emit('open', isOpen);
    }

    onAction(action: AssignmentAction) {
        if (!action.route) {
            this.execute(action);
        }
    }

    execute(action: AssignmentAction) {
        return action.callback(this, this.assignment);
    }
}
</script>

<style lang="scss">
.chip-as-icon {
    min-width: 24px;
}
</style>
