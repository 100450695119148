<template>
    <v-container v-if="isBusy && $vuetify.breakpoint.mdAndUp">
        <assignments-list-header
            v-if="$vuetify.breakpoint.mdAndUp"
            :loading="loading"
        />
        <assignments-list-row
            v-for="assignment in assignments.length || 10"
            :key="'assignment-row-' + assignment"
            loading
        />
    </v-container>
    <v-container v-else-if="isBusy">
        <assignment-card
            v-for="i in assignments.length || 10"
            :key="i"
            loading
        />
    </v-container>
    <v-container v-else-if="hasData && $vuetify.breakpoint.mdAndUp">
        <assignments-list-header />
        <assignments-list-row
            v-for="(assignment, index) in assignments"
            :key="'assignment-row-' + assignment.id + '-' + index"
            :assignment="assignment"
            :action="getDefaultAction(assignment)"
            :can-set-priority="canSetPriority(assignment)"
            @show-comments="showComments"
            @activate="setActiveItem"
            @update="update"
        />
        <assignment-comments
            :assignment-id="showCommentsForAssignmentId"
            @close="hideComments"
            @update="update"
        />

        <assignment-action-dialogs v-on="$listeners" />
    </v-container>
    <v-container v-else-if="hasData" class="px-0">
        <assignment-card
            v-for="(assignment, index) in assignments"
            :key="'assignment-card-' + assignment.id + '-' + index"
            :assignment="assignment"
            :action="getDefaultAction(assignment)"
            class="mb-2"
            @show-comments="showComments"
        />

        <assignment-comments
            :assignment-id="showCommentsForAssignmentId"
            @close="hideComments"
            @update="update"
        />
    </v-container>
    <a-message-bar v-else>No author requests found</a-message-bar>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { mapGetters } from 'vuex';

import { ProvideReactive } from '@/utils/decorators';

import { AssignmentComments } from '@/components/Authoring/AssignmentComments';
import { AssignmentActionDialogs } from '@/components/Authoring/AssignmentActions/AssignmentActionDialogs';
import { AssignmentCard } from '@/components/Authoring/AssignmentCard';
import { AMessageBar } from '@/components/AMessageBar';

import AssignmentActionsFactory from '@/components/Authoring/AssignmentActions/AssignmentActionsFactory';

import AssignmentsListHeader from './AssignmentsListHeader.vue';
import AssignmentsListRow from './AssignmentsListRow.vue';

import type { Assignment } from '@/types/Assignment';

const AssignmentsListProps = Vue.extend({
    name: 'AssignmentsList',
    props: {
        assignments: {
            type: Array as PropType<Partial<Assignment>[]>,
            default(): Assignment[] {
                return [];
            }
        },
        loading: {
            type: Boolean,
            default() {
                return true;
            }
        },
        updating: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component<AssignmentsList>({
    components: {
        AssignmentsListHeader,
        AssignmentsListRow,
        AssignmentComments,
        AssignmentActionDialogs,
        AssignmentCard,
        AMessageBar
    },
    computed: {
        ...mapGetters('user', ['isAuthor', 'isEditor'])
    }
})
export default class AssignmentsList extends AssignmentsListProps {
    isAuthor!: boolean;
    isEditor!: boolean;

    showCommentsForAssignmentId = 0;
    authouringActions: AssignmentActionsFactory | null = null;
    // Action dialogs inject that
    @ProvideReactive() assignment: Partial<Assignment> | null = null;

    get hasData() {
        return Boolean(this.assignments.length);
    }

    get isBusy() {
        return this.loading || this.updating;
    }

    canSetPriority(assignment: Partial<Assignment>) {
        return this.getAuthoringAction(assignment).hasAction('priority');
    }

    getAuthoringAction(assignment: Partial<Assignment>) {
        if (this.authouringActions) {
            return this.authouringActions;
        }
        this.authouringActions = new AssignmentActionsFactory(
            assignment,
            this.isEditor,
            this.isAuthor
        );

        return this.authouringActions;
    }

    showComments(id: number) {
        this.showCommentsForAssignmentId = id;
    }

    hideComments() {
        this.showCommentsForAssignmentId = 0;
    }

    setActiveItem(assignment: Partial<Assignment>) {
        this.assignment = assignment;
    }

    getDefaultAction(assignment: Partial<Assignment>) {
        const actions = this.getAuthoringAction(assignment).getActions();

        const editAction = actions.find(action =>
            ['write', 'edit'].includes(action.action)
        );

        const commentsAction = actions.find(
            action => action.action === 'review'
        );

        return editAction || commentsAction;
    }

    update() {
        this.$emit('update', true);
    }
}
</script>
